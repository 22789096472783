import React from "react";
import styled from "styled-components";
import theme from "theme";

const Tile = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 50px;
  padding-right: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  ${theme.screens.lgScreen} {
    padding-right: 0;
    margin-bottom: 80px;
    justify-content: flex-start;
  }
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 48px;
    height: 2px;
    background-color: ${theme.colors.red};
  }
`;

const Desc = styled.div`
  margin: 40px 0;
  font-size: 14px;
`;

const Btn = styled.a`
  font-size: 14px;
  color: ${theme.colors.red};

  ${theme.mixins.onEvent} {
    color: ${theme.colors.red};

    img {
      margin-left: 15px !important;
    }
  }
`;

const DownloadTile = ({ title, desc, link }) => {
  return (
    <Tile>
      <div>
        <Title>{title}</Title>
        <Desc>{desc}</Desc>
      </div>
      <Btn href={link} rel="noreferrer" target="_blank" download>
        Download
        <img
          src={require("assets/icons/download-arrow.svg").default}
          style={{ marginLeft: 10, transition: "all .25s" }}
          alt=""
        />
      </Btn>
    </Tile>
  );
};

export default DownloadTile;
