import React from "react";
import styled from "styled-components";
import theme from "theme";

const PersonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 50px;

  ${theme.screens.lgScreen} {
    margin: 0 auto 80px;
    justify-content: center;
  }

  ${theme.screens.smScreen} {
    flex-direction: column;
    align-items: center;
  }
`;

const Avatar = styled.img`
  border-radius: 50px;
  max-width: 100%;
  height: auto;
  max-height: 103px;
  margin: 0px 30px 0 0;

  ${theme.screens.smScreen} {
    margin: 0 0 60px 0;
  }
`;

const Name = styled.h3`
  font-size: 20px;
  font-weight: 500;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: -15px;
    width: 48px;
    height: 2px;
    background-color: ${theme.colors.red};
  }
`;
const Position = styled.div`
  font-size: 16px;
  margin-bottom: 25px;
`;

const ContactLink = styled.a`
  font-size: 16px;
  display: block;

  ${theme.mixins.onEvent} {
    color: ${theme.colors.red}!important;
  }
`;

const MediaPerson = ({ name, position, email, phone, avatar }) => {
  return (
    <PersonContainer>
      <Avatar src={avatar} />
      <div>
        <Name>{name}</Name>
        <Position>{position}</Position>
        <ContactLink>{email}</ContactLink>
        <ContactLink>{phone}</ContactLink>
      </div>
    </PersonContainer>
  );
};

export default MediaPerson;
