import "styles/pages/page-media.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import SectionHeading from "components/SectionHeading";
import MediaPerson from "../components/MediaPerson";
import DownloadTile from "../components/DownloadTile";

const MediaPage = ({ pageContext }) => {
  const persons = pageContext.data.mediaAndPressMaterials.persons;
  const files = pageContext.data.mediaAndPressMaterials.pressKits;
  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title={pageContext.title}
        image={require("assets/images/media-bg.jpg").default}
      />
      <section className="media-page-section">
        <div className="container">
          <SectionHeading
            title="Contacts"
            subtitle="For more press enquiries please contact"
            margin="both"
          />
          <div className="media-page-section__persons">
            <div className="row">
              {persons?.map((item, index) => {
                return (
                  <div className="col-lg-4" key={index}>
                    <MediaPerson
                      name={item.name}
                      position={item.position}
                      avatar={item.avatar.sourceUrl}
                      phone={item.phone}
                      email={item.email}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="media-page-section__files">
          <div className="container">
            <SectionHeading title="Press kit" margin="bottom" />
            <div className="row">
              {files?.map((item, index) => {
                return (
                  <div
                    className="col-lg-4 media-page-section__file-col"
                    key={index}
                  >
                    <DownloadTile
                      title={item.title}
                      desc={item.desc}
                      link={item.file.mediaItemUrl}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default MediaPage;
